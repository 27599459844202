import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import style from './AsideMenu.module.scss';

import { Roles } from 'helpers/roles';

import { ROUTES } from 'routes/routes';

import NavigationItem from './NavigationItem';

@observer
class Navigation extends Component {
  render() {
    return (
      <nav className={style.navigation}>
        <ul className={classNames(style.navigation__menu, style.menu__top)}>
          <NavigationItem
            roles={[Roles.accountant, Roles.hostess]}
            iconType="personIcon"
            to={ROUTES.employees}
          >
            <FormattedMessage id="asideMenu.item.employees.title" />
          </NavigationItem>

          <NavigationItem
            roles={[Roles.service]}
            iconType="basket"
            to={ROUTES.service}
          >
            <FormattedMessage id="title.online.ordering" />
          </NavigationItem>

          <NavigationItem
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            iconType="statistic"
            to={ROUTES.admin.report}
          >
            <FormattedMessage id="title.report" />
          </NavigationItem>

          <NavigationItem
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            iconType="schedule"
            to={ROUTES.admin.menu}
          >
            <FormattedMessage id="title.menu" />
          </NavigationItem>

          <NavigationItem
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            iconType="menu"
            to={ROUTES.admin.menuItems}
          >
            <FormattedMessage id="title.dishes" />
          </NavigationItem>

          <NavigationItem
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            iconType="users"
            to={ROUTES.admin.staff}
          >
            <FormattedMessage id="title.users" />
          </NavigationItem>

          <NavigationItem
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            iconType="discount"
            to={ROUTES.admin.discounts}
          >
            <FormattedMessage id="title.discounts" />
          </NavigationItem>

          <NavigationItem
            roles={[Roles.admin, Roles.bayerRestrictedAdmin]}
            iconType="recognition"
            to={ROUTES.admin.recognition}
          >
            <FormattedMessage id="title.recognition" />
          </NavigationItem>

          <NavigationItem
            roles={[Roles.archiveAdmin]}
            iconType="archive"
            to={ROUTES.archiveAdmin.archive}
          >
            <FormattedMessage id="title.archive" />
          </NavigationItem>
        </ul>

        <ul className={classNames(style.navigation__menu, style.menu__bottom)}>
          <NavigationItem
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            iconType="settings"
            to={ROUTES.admin.settings}
          >
            <FormattedMessage id="title.settings" />
          </NavigationItem>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
